/**
 * Format a number to a locale string.
 */
function formatNumber(value?: number | null) {
   if (!isValidNumber(value))
      return '-'

   const { locale } = useI18n()

   return value?.toLocaleString(toValue(locale))
}

/**
 * Currency.
 */
export function formatCurrency(value?: number | null) {
   if (!isValidNumber(value))
      return '-'

   const { locale } = useI18n()

   return value?.toLocaleString(toValue(locale), { currency: 'USD', style: 'currency' })
}

/**
 * Percentage.
 */
export function formatPercentage(value?: number | null) {
   if (!isValidNumber(value))
      return '-'

   return `${formatValue(value * 100)}%`
}

/**
 * Format a score.
 */
export function formatValue(value?: number | null) {
   if (!isValidNumber(value))
      return '-'

   return formatNumber(Math.round(value * 10) / 10)
}

/**
 * Check if number is valid.
 */
export function isValidNumber(value?: number | null): value is number {
   return Number.isFinite(value)
}
